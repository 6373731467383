<template>
  <div>
    <h1 class="h2">給与承認者設定</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
    </div>

    <v-form v-model="valid" ref="form" @submit.prevent="submit">
      <template>
        <v-autocomplete
          v-model="selectFilterAffiliations"
          :items="filterAffiliations"
          :disabled="edit"
          label="事業所コード"
          :rules="[Rules.Required]"
          outlined
          multiple
          chips
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <!--<v-autocomplete
          v-model="approvalA"
          :items="incodesA"
          label="現場責任者"
          outlined
          clearable
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
          multiple
        >
        </v-autocomplete>-->
        <v-autocomplete
          v-model="approvalB"
          :items="incodesB"
          label="経理・事務"
          outlined
          clearable
          prepend-icon="mdi-form-select"
          return-object
          multiple
        >
        </v-autocomplete>
        <!-- <v-autocomplete
          v-model="approvalC"
          :items="incodesC"
          label="担当管理者"
          outlined
          clearable
          prepend-icon="mdi-form-select"
          return-object
          multiple
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="approvalD"
          :items="incodesD"
          label="経理・事務"
          outlined
          clearable
          prepend-icon="mdi-form-select"
          return-object
          multiple
        >
        </v-autocomplete> -->
      </template>

      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ApprovalCharge from "@/models/ApprovalCharge";
import { CODE_GROUP as CodeGroup } from "@/defines";
export default {
  name: "ApprovalDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      incodesA: [],
      incodesB: [],
      incodesC: [],
      incodesD: [],
      filterAffiliations: [],
      selectFilterAffiliations: [],
      approvalA: [],
      approvalB: [],
      approvalC: [],
      approvalD: [],
      targetItems: [],
      sort: null,
      incodesBMap: {},
      incodesCMap: {},
      incodesDMap: {}
    };
  },
  methods: {
    getNameCodeMap(list) {
      return new Map(
        list.map(obj => {
          return [obj.name, obj.code];
        })
      );
    },
    getListOfName(list) {
      let result = [];
      result = list.map(item => {
        return item.name;
      });
      return result;
    },
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      console.log("approvalA", this.approvalA);
      console.log("approvalB", this.approvalB);
      console.log("approvalC", this.approvalC);
      console.log("aaprovalD", this.approvalD);
      const appA = "";
      const appBList = [];
      const appC = "";
      const appD = "";
      if (this.approvalB === null) this.approvalB = [];
      // if (this.approvalC === null) this.approvalC = [];
      // if (this.approvalD === null) this.approvalD = [];

      for (let b = 0; b < this.approvalB.length; b++) {
        const B = this.incodesBMap.get(this.approvalB[b]);
        if (B !== undefined) appBList.push(B);
      }
      // for (let c = 0; c < this.approvalC.length; c++) {
      //   const C = this.incodesCMap.get(this.approvalC[c]);
      //   if (C !== undefined) appCList.push(C);
      // }
      // for (let d = 0; d < this.approvalD.length; d++) {
      //   const D = this.incodesDMap.get(this.approvalD[d]);
      //   if (D !== undefined) appDList.push(D);
      // }
      try {
        const params = new ApprovalCharge(
          this.selectFilterAffiliations,
          appA,
          appBList.join(","),
          appC,
          appD,
          "0"
        );
        await this.$post(this.Paths.approvalList, params);
        this.$info("更新しました。");
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const params = {
        selectDivision: this.params.args.selectDivision,
        selectFilterAffiliations: this.params.args.selectFilterAffiliations
      };
      this.$router.push({ name: "ApprovalList", params });
    },
    async getUsers(value) {
      console.log("getUsers");
      return await this.$get(this.Paths.shainSorted, "query=" + value);
    }
  },
  async created() {
    console.log("created", this.$route.params, this.args);
    this.params = this.$route.params;
    console.log(this.params);
    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = false;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      const code1 = this.$store.state.shozokuCode1;

      this.affiliationsItems = await this.$get(this.Paths.shozoku);
      this.filterAffiliations = this.affiliationsItems;
      if (code1 && code1 !== "9") {
        this.filterAffiliations = this.affiliationsItems.filter(
          e => e.refcode === this.$store.state.shozokuCode1
        );
      }
      const incodesBTemp = await this.getUsers("5");
      // const incodesCTemp = await this.getUsers("2");
      // const incodesDTemp = await this.getUsers("5");
      this.incodesB = this.getListOfName(incodesBTemp);
      // this.incodesC = this.getListOfName(incodesCTemp);
      // this.incodesD = this.getListOfName(incodesDTemp);
      this.incodesBMap = this.getNameCodeMap(incodesBTemp);
      // this.incodesCMap = this.getNameCodeMap(incodesCTemp);
      // this.incodesDMap = this.getNameCodeMap(incodesDTemp);

      if (!this.add) {
        const incodes = [];
        this.params.args.targetItems.forEach(t => {
          incodes.push(String(t.affiliationId));
          this.approvalB = t.approvalBList;
          this.approvalC = t.approvalCList;
          this.approvalD = t.approvalDList;
        });
        this.selectFilterAffiliations = incodes;
        console.log(this.selectFilterAffiliations);
        console.log("B", this.approvalB);
        console.log("C", this.approvalC);
        console.log("D", this.approvalD);
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
